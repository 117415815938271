@import '../../scss/required';

$inpage-nav-btn-padding-y: 1.5rem;

.inpage-nav {
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 70;

  // since the component has inner padding on nested elements => compensate the vertical space with negative margin
  margin-top: -$inpage-nav-btn-padding-y;
  margin-bottom: calc(
    #{$component-vertical-space-mobile} - #{$inpage-nav-btn-padding-y}
  ) !important;

  @include media-breakpoint-up(lg) {
    margin-bottom: calc(#{$component-vertical-space} - #{$inpage-nav-btn-padding-y}) !important;
  }

  nav {
    display: flex;
    justify-content: center;
  }

  &-list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    @include media-breakpoint-down(sm) {
      justify-content: left;
    }

    li {
      text-align: center;
      min-width: 120px;
      padding: 0 $spacer-2;
    }

    &__link {
      padding: 0 1rem;

      .btn {
        padding: $inpage-nav-btn-padding-y 0;
        &:hover,
        &:active {
          border-bottom: 1px solid $unique;
        }
      }
    }
  }
}
