@import '../../scss/required';

.faq {
  &__collapse {
    border: 1px solid $gray;
    margin-bottom: 0.75rem;
  }

  &__collapse-inner {
    padding: 0.75rem;

    @include media-breakpoint-up(lg) {
      padding: 1rem 2rem;
    }
  }

  &__collapse-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0;
    padding: 0.75rem;
    background-color: #fff;
    text-decoration: none;
    text-align: left;
    @include transition(background-color);

    @include media-breakpoint-up(lg) {
      padding: 1rem 2rem;
    }

    &:hover,
    &:focus {
      background-color: $gray;
    }

    &:focus,
    &:focus:not(:focus-visible) {
      outline: none;
    }

    &:focus {
      box-shadow: 0 0 0 0.15rem $blue;
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }
  }

  &__collapse-icon {
    flex-shrink: 0;
    transition-property: fill, transform;
  }

  &__collapse-icon--open {
    fill: $unique;
    stroke: $unique;
  }

  &__collapse-icon--hidden {
    display: none;
  }
}
