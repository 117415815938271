@import '../../scss/required';

.events-list {
  &__heading {
    margin: 2rem 0;
  }

  @include media-breakpoint-up(lg) {
    &--wide {
      .events-list-item {
        display: flex;
        flex-direction: row;

        &__image {
          height: 100%;
          width: 50%;
          padding-right: 0.75rem;

          img {
            max-height: 350px;
          }
        }

        &__info {
          height: 100%;
          padding: 0 1rem;
          background: $gray;

          &-title {
            height: 150px;
          }

          .btn {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  &-item {
    height: 100%;
    padding: 0;
    background-color: $gray;

    &__image {
      height: 50%;

      img {
        height: 100%;
        max-height: 200px;
        width: 100%;
        object-fit: cover;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 50%;
      padding: 0 1.5rem;
      background: $gray;

      &-dates {
        display: flex;
        flex-direction: row;
        padding: 1rem 0 0.75rem;

        p {
          width: auto;
          padding: 0;
        }
      }

      .btn {
        margin-bottom: 1rem;
      }
    }
  }
}
