@import '../../scss/required';

$small-image-height: 176px;
$big-image-height-mobile: 159px;
$focus-border: 2px solid $blue;
$small-rect-size: 48px;

.gallery {
  position: relative;

  &__rect {
    position: absolute;
    top: $spacer-10;
    left: calc(20% - #{$spacer-1});
    z-index: -1;

    @include media-breakpoint-up(md) {
      left: 0;
      width: 100%;
    }

    span {
      height: 476px;
      width: 80%;
      display: inline-block;
      background: $gray-light;

      @include media-breakpoint-up(lg) {
        width: 100%;
        height: 448px;
      }

      @include media-breakpoint-down(md) {
        height: 190px;
      }
    }

    @include media-breakpoint-down(lg) {
      padding-bottom: $spacer-8;
    }
  }

  &__images-container {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__image {
    &--big {
      padding-right: $spacer-3;

      a:focus img {
        outline: $focus-border;
      }

      img {
        height: 446px;
        outline: transparent solid 2px;
        width: 100%;
        object-fit: cover;
        @include media-breakpoint-down(md) {
          height: 162px;
        }
      }

      @include media-breakpoint-down(lg) {
        padding-bottom: $spacer-8;
      }

      @include media-breakpoint-down(md) {
        height: 162px;
        padding-bottom: $spacer-14;
      }
    }

    &--small {
      outline: 0;
      border: 0;
      background: transparent;
      padding: 0;
      margin-bottom: 1rem;

      button {
        border: 0;
        outline: 0;
      }

      &:focus {
        border: 0;
        outline: 0;
      }

      button:focus {
        outline: $focus-border;
      }

      &:focus img {
        outline: $focus-border;
      }

      img {
        height: $small-image-height;
        object-fit: cover;

        @include media-breakpoint-down(lg) {
          height: $big-image-height-mobile;
        }

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
    &-count {
      background: $red;
      height: $small-image-height;
      padding-top: 35%;
      color: $white;
      position: relative;
      width: 100%;

      @include media-breakpoint-down(lg) {
        height: $big-image-height-mobile;
      }

      &__number {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &__number--mobile {
        background: $red;
        position: absolute;
        bottom: $small-rect-size;
        color: $white;
        width: $small-rect-size;
        height: $small-rect-size;
        right: $spacer-3;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        outline: 0;

        &:focus {
          border: $focus-border;
        }

        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }
  }
}
