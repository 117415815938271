@import '../../scss/required';

.collapse {
  visibility: hidden;

  &--transitioning {
    height: 0;
    overflow: hidden;
    @include transition(height);
  }

  &--visible {
    display: block;
    visibility: visible;
  }
}
