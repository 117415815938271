@import '../../scss/required';

.grid-5 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);

  @include media-breakpoint-down(md) {
    .grid-5__column {
      grid-column: 1/-1;
    }
  }

  @include media-breakpoint-up(md) {
    .grid-5__column:first-of-type {
      align-self: center;
      grid-column: 1/2;
      grid-row: 1/3;
    }
  }
  @include media-breakpoint-up(xxl) {
    display: flex;
    .grid-5__column:first-of-type {
      align-self: stretch;
    }
  }
}

.grid-5__column {
  margin-bottom: $spacer-8;
  padding: $spacer-2;

  @include media-breakpoint-up(md) {
    margin-bottom: $spacer-5;
  }
}
