@import '../../scss/required';

.big-image {
  &__img {
    display: block;
    width: 100%;
    object-fit: cover;

    @include media-breakpoint-down(md) {
      height: 60%;
    }
  }

  &__text {
    font-style: italic;
    margin-top: $spacer-4;
  }

  @include media-breakpoint-only(md) {
    padding: 0 16px;
  }
}
