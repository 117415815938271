@import '../../scss/required';

.offices-inset {
  background: $gray-light;

  position: relative;

  svg {
    fill: $red;
    margin-right: $spacer-3;
    padding-bottom: 2px;
  }

  h3 {
    margin-top: $spacer-6;
    margin-bottom: $spacer-16;
  }

  .address {
    display: inline-block;
    font-size: $body-font-size;
    font-weight: bold;
  }

  & > .btn {
    position: absolute;
    bottom: $spacer-6;
  }
}
