@import '../../scss/required';

.hero-banner {
  .container {
    position: relative;
  }

  h2 {
    margin-top: 0;

    @include media-breakpoint-down(lg) {
      font-size: $h2-font-size;
      line-height: $h2-line-height;
      margin-top: $spacer-8;
    }
  }

  &__img {
    object-fit: cover;
    height: 576px;
    display: block;
    @include media-breakpoint-down(lg) {
      height: 368px;
    }

    @include media-breakpoint-down(md) {
      height: 312px;
    }
  }

  &__asset-wrapper {
    position: relative;
    overflow: hidden;
  }

  &__play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: $spacer-3;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50px;
    border: none;
    transform: translate(-50%, -50%);

    &:focus {
      box-shadow: 0 0 0 0.15rem $blue;
      outline: none;
    }
  }

  &__rect {
    position: absolute;
    height: 90%;
    width: 60%;
    background: $gray-light;
    top: 0;
    right: 0;
    z-index: -1;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__content {
    @include media-breakpoint-down(md) {
      margin-bottom: $spacer-10;
    }
  }

  .btn--primary {
    font-weight: normal;
    margin-right: $spacer-12;
  }

  .btn {
    @include media-breakpoint-down(sm) {
      margin-right: $spacer-16;
      margin-bottom: $spacer-4;
    }
  }

  p {
    margin-top: $spacer-5;
    margin-bottom: $spacer-9;

    @include media-breakpoint-down(lg) {
      margin-top: $spacer-2;
    }
  }
}
