@import '../../scss/required';

.page-intro {
  &-breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.5rem;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    &__slash {
      padding: 0 $spacer-2;
    }

    .h4.btn {
      margin-right: 1rem;
      display: flex;
      align-items: center;

      svg {
        margin-right: $spacer-2;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        display: block;
        margin-bottom: $spacer-6;
      }
    }

    .breadcrumb {
      display: flex;
      flex-direction: row;
      margin-bottom: 0;
      list-style: none;
      flex-wrap: wrap;

      @include media-breakpoint-down(sm) {
        padding-left: 5px;
      }

      &-item {
        margin: 0;
      }
    }
  }

  h1 {
    margin-bottom: 1rem;
  }

  image {
    display: block;
    width: 100%;
    object-fit: cover;
  }
}
