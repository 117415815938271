@import '../../scss/required';

.richtext {
  ul {
    padding-left: 1rem;

    li {
      padding-left: 1rem;

      &::marker {
        color: $red;
        font-size: 0.8rem;
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}
