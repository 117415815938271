@import '../../scss/required';

.teaserbox {
  img {
    max-width: 100%;
    height: auto;
    object-fit: unset;
  }

  &__play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: $spacer-3;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50px;
    border: none;
    transform: translate(-50%, -50%);

    &:focus {
      box-shadow: 0 0 0 0.15rem $blue;
      outline: none;
    }
  }

  &__asset-wrapper {
    position: relative;
    overflow: hidden;
  }

  .small img {
    margin-bottom: 1.5rem;
    max-width: 183px;
    max-height: 176px;
  }
}
