@import '../../scss/required';

.footer {
  padding-bottom: $spacer-8;
  background-color: #fff;

  &__logo {
    fill: $unique;
  }

  &__list-toplinks {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    &__item {
      margin-bottom: $spacer-5;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        display: block;
      }
    }
  }

  &__list-social,
  &__list-sublinks {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__item {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__list-social {
    &__item {
      margin-right: $spacer-2;

      a {
        display: block;
        line-height: 1;
        padding: $spacer-3;

        &:hover,
        &:focus {
          .icon {
            fill: $unique;
          }
        }
      }
    }
  }

  &__list-sublinks {
    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }

    &__item {
      margin-right: $spacer-8;
    }
  }

  &__office {
    padding: $spacer-6;
    background-color: $gray;
  }

  &__address,
  &__office__address {
    &,
    p {
      line-height: $body-line-height;
    }

    p {
      margin-bottom: 0;
    }
  }

  &__office__address {
    &,
    p {
      font-size: $small-font-size;
    }
  }

  &__collapse-inner {
    padding: $spacer-3;
  }

  &__collapse-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0;
    padding: $spacer-3;
    background-color: #fff;
    text-decoration: none;
    border-top: 1px solid $gray;
    @include transition(background-color);

    &:hover,
    &:focus {
      background-color: $gray;
    }

    &:focus,
    &:focus:not(:focus-visible) {
      outline: none;
    }

    &:focus {
      box-shadow: 0 0 0 0.15rem $blue;
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }
  }

  &__collapse-icon {
    flex-shrink: 0;
    transition-property: fill, transform;
  }

  &__collapse-icon--open {
    fill: $unique;
  }
}
