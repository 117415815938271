@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

h1,
.h1 {
  font-family: $font-family-sans-serif;
  font-size: $h1-mobile-font-size;
  font-weight: $font-weight-bold;
  line-height: $h1-mobile-line-height;
  letter-spacing: $h1-mobile-letter-spacing;
  color: $primary;

  @include media-breakpoint-up(md) {
    font-size: $h1-font-size;
    line-height: $h1-line-height;
    letter-spacing: $h1-letter-spacing;
  }
}

h2,
.h2 {
  font-family: $font-family-sans-serif;
  font-size: $h2-mobile-font-size;
  font-weight: $font-weight-bold;
  line-height: $h2-mobile-line-height;
  letter-spacing: $h2-mobile-letter-spacing;
  color: $primary;

  @include media-breakpoint-up(md) {
    font-size: $h2-font-size;
    line-height: $h2-line-height;
    letter-spacing: $h2-letter-spacing;
  }
}

h3,
.h3 {
  font-family: $font-family-sans-serif;
  font-size: $h3-font-size;
  font-weight: $font-weight-bold;
  line-height: $h3-line-height;
  letter-spacing: $h3-letter-spacing;
  color: $primary;

  // H3 Links
  &.h3-link {
    .display-hover {
      display: none;
    }

    &:hover {
      a {
        color: $unique;
      }

      .display-hover {
        display: inline-block;
        fill: $unique;
      }
    }

    a {
      display: inline-block;
      font-size: $body-font-size;
      line-height: $h4-line-height;
      letter-spacing: $h4-letter-spacing;
      text-decoration: none;

      &:hover {
        position: relative;
        text-decoration: none;
      }

      &:focus {
        outline: $blue solid 2px;
      }

      &:active {
        text-decoration: none;
        color: $primary;
      }
    }
  }
}

h4,
.h4 {
  font-family: $font-family-sans-serif;
  font-size: $h4-font-size;
  font-weight: $font-weight-bold;
  line-height: $h4-line-height;
  letter-spacing: $h4-letter-spacing;
  color: $primary;
}

body,
.body {
  font-family: $font-family-sans-serif;
  font-size: $body-font-size;
  line-height: $body-line-height;
  letter-spacing: $body-letter-spacing;
  color: $body-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
.p {
  font-family: $font-family-sans-serif;
  font-size: $p-font-size;
  line-height: $p-line-height;
  letter-spacing: $p-letter-spacing;
  color: $primary;
}

caption,
.caption {
  font-family: $font-family-sans-serif;
  font-size: $caption-font-size;
  line-height: $caption-line-height;
  letter-spacing: $caption-letter-spacing;
  color: $primary;
}

// Body, Caption, Underlined Links
a {
  font-family: $font-family-sans-serif;
  font-size: $body-font-size;
  line-height: $body-line-height;
  letter-spacing: $body-letter-spacing;
  color: $body-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: $blue solid 2px;
  }
}

a.caption {
  font-family: $font-family-sans-serif;
  font-size: $caption-font-size;
  line-height: $caption-line-height;
  letter-spacing: $caption-letter-spacing;
  color: $primary;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: $blue solid 2px;
  }
}

a.underlined {
  font-family: $font-family-sans-serif;
  font-size: $caption-font-size;
  line-height: $caption-line-height;
  letter-spacing: $caption-letter-spacing;
  color: $primary;
  text-decoration: underline;

  &:hover {
    color: $unique;
    text-decoration: none;
  }

  &:focus {
    outline: $blue solid 2px;
  }
}

h3.disabled,
a.disabled {
  opacity: 0.38;
}
