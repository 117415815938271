@import '../../scss/required';

.career-positions {
  &__heading {
    margin-bottom: 2.25rem;
  }

  &-item {
    margin-bottom: 1rem;
    padding: 2rem 1rem;
    background-color: $gray;
    @include media-breakpoint-only(md) {
      padding: 2rem 1.5rem;
    }
    @include media-breakpoint-up(lg) {
      padding: 2rem 2rem 1.5rem;
    }

    &__name {
      height: 100px;
      margin-bottom: 1rem;
    }

    &__title {
      margin-bottom: 0.5rem;
    }

    hr {
      margin: 1rem 0 0.5rem;
    }

    &__posted {
      font-style: italic;
      @include media-breakpoint-up(lg) {
        text-align: right;
      }
    }
  }
}
