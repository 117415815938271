%form-control {
  top: 0;
  display: block;
  width: 100%;
  background: $input-bg-color;
  border: none;
  border-bottom: $input-border-width solid $input-border-color;
  padding: $input-padding;
  caret-color: $red;
  transition-property: padding, border, background-color;
  transition-duration: $transition-duration-default;
  transition-timing-function: $transition-timing-function;

  &:hover,
  &:focus {
    background: $input-focus-bg-color;
    border-bottom-color: $input-focus-border-color;
  }
}

%form-control-focus {
  border-bottom-color: $input-focus-border-color;
  outline: none;
  padding: $input-focus-padding;
}

%form-control-label {
  color: $black;
  font-size: $body-font-size;
  top: 12px;
  left: 16px;
}

%form-control-focus-label {
  color: $black;
  font-size: $extra-small-font-size;
  pointer-events: none;
  position: absolute;
  left: 16px;
  top: 0;
  transition-property: top, left, font-size;
  transition-duration: $transition-duration-default;
  transition-timing-function: $transition-timing-function;
}

%form-control-error {
  color: $red;
  font-size: $extra-small-font-size;
  margin-left: $spacer-3;
}
