@import '../../scss/required';

.contact-us {
  &-row {
    padding-bottom: 0.5rem;

    @include media-breakpoint-up(md) {
      padding-bottom: 2.5rem;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 3rem;
    }

    &-data {
      a {
        text-decoration: underline;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }

      svg {
        margin-bottom: 0.75rem;
      }

      &--mail {
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
    }
  }
  &-item {
    padding: 1rem 0;

    @include media-breakpoint-up(md) {
      padding: 1.5rem 0 3rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 2.5rem 0 3rem;
    }

    &-lang {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      svg {
        margin-bottom: 0.5rem;
      }
    }

    &-contact-data {
      display: flex;
      flex-direction: column;

      &__name {
        margin-bottom: 1rem;
      }

      &__row {
        display: flex;
        flex-direction: row;
        margin-bottom: 0.5rem;

        & p {
          margin-right: 1rem;
        }
      }
    }
  }
  svg {
    fill: $red;
    margin-right: 1rem;
  }
}
