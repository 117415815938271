@import '../../scss/required.scss';

.video-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.9);

  &__header {
    display: flex;
    justify-content: flex-end;
  }
  &__close-button {
    margin: $spacer-6;
    padding: 0;
    border: none;
    background: transparent;

    &:focus {
      box-shadow: 0 0 0 0.15rem $blue;
      outline: none;
    }
  }

  &__video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1000px;
    max-height: 100vh;
    aspect-ratio: 16/9;
  }
}
