@import '../../scss/required';

$menu-height: 88px;
$menu-item-border: 2px;

.navigation {
  height: $menu-height;
  position: relative;
  background: white;
  margin-bottom: $spacer-8;

  .navbar-brand {
    z-index: 80;
  }

  .collapse:not(.show) {
    display: none;
    visibility: visible;
  }

  .collapse.show {
    visibility: visible;
  }

  &__container {
    position: relative;
    height: $menu-height;
  }

  &__inputs {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    z-index: 80;

    button,
    a {
      svg {
        fill: $white;
      }
    }

    @include media-breakpoint-down(xxl) {
      display: none;
    }
  }

  .navbar-collapse {
    flex-basis: auto;

    @include media-breakpoint-down(xxl) {
      width: 100%;
      min-height: calc(100vh - #{$menu-height});
      position: absolute;
      top: $menu-height;
      left: 0;
      z-index: 75;
      background-color: $white;
    }
  }

  /* logo */
  svg {
    fill: $unique;
  }

  &__toggle-button {
    display: flex;
    align-items: center;
    position: absolute;
    background: transparent;
    right: ($grid-gutter-width / 2);
    border: 0;

    @include media-breakpoint-up(xxl) {
      display: none;
    }

    svg {
      fill: $black;
    }

    &:hover,
    &:focus {
      svg {
        fill: $unique;
      }
    }

    &:focus {
      border: 0;
      outline: $blue solid 2px;
    }

    padding-right: 0 !important;

    span {
      margin-right: $spacer-4;
      font-size: $small-font-size;
    }
  }

  &__menu {
    justify-content: center;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translatex(-50%);
    z-index: 75;

    @include media-breakpoint-down(xxl) {
      position: static;
      transform: none;
      background: $white;
      padding-top: $spacer-2;
      border-top: 1px solid $gray-darker;
    }

    .nav-item {
      border-bottom: $menu-item-border solid transparent;
      position: initial;

      @include media-breakpoint-down(xxl) {
        border-bottom: 0;
      }

      svg {
        fill: $black;
      }

      .nav-link {
        display: flex;
        align-items: center;
        padding: $spacer-4;
        font-weight: $font-weight-bold;
        position: initial;
        text-decoration: none;
        @include transition(color);

        &:focus,
        &:hover {
          color: $unique;

          svg {
            fill: $unique;
          }
        }

        @include media-breakpoint-up(xxl) {
          height: $menu-height - $menu-item-border;
          padding: 0 $spacer-4 !important;
        }
      }

      &--active,
      &:hover {
        @include media-breakpoint-up(xxl) {
          > a {
            color: $unique;
          }
          svg {
            fill: $unique;
          }
          border-bottom: $menu-item-border solid $red;
        }
      }
    }

    &__button {
      margin: $spacer-4 $spacer-4 0;
      padding: $spacer-6 0;
      border-bottom: 1px solid $gray-darker;
      border-top: 1px solid $gray-darker;

      @include media-breakpoint-up(xxl) {
        display: none;
      }

      svg {
        fill: $white;
      }
    }
  }

  &__submenu {
    width: 100%;
    height: auto;
    background: white;
    position: absolute;
    left: 0;
    top: $menu-height;
    display: none;
    padding-top: $spacer-8;
    padding-bottom: $spacer-16;
    border-top: 1px solid $gray-darker;
    border-bottom: 1px solid $gray-darker;
    z-index: 78;

    &_active {
      display: block;
    }

    &__lists {
      @include media-breakpoint-down(xxl) {
        li a {
          display: flex;
          align-items: center;
          padding-block: $spacer-4;
        }
      }
      @include media-breakpoint-up(xxl) {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(5, max-content);
        grid-template-columns: repeat(6, minmax(max-content, 1fr));
        grid-column-gap: $spacer-5;
        grid-row-gap: $spacer-6;
      }
    }

    &__lists.has_submenu {
      @include media-breakpoint-up(xxl) {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: auto;
        grid-auto-flow: row;

        li:not(:first-of-type) {
          margin-top: $spacer-6;
        }
      }

      svg {
        fill: currentColor;
      }
    }

    @include media-breakpoint-down(xxl) {
      padding-bottom: 0;
      border-bottom: 0;
      position: static;
    }

    &__back-button {
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $red;
      font-weight: $font-weight-bold;
      margin-left: -$spacer-2;
      padding-bottom: $spacer-4;
      background: transparent;
      border: 0;

      &:focus {
        outline: $blue solid 2px;
      }

      span {
        margin-left: $spacer-2;
      }
    }

    ul {
      position: relative;
      list-style: none;
      padding-left: 0;

      @include media-breakpoint-down(xxl) {
        padding-left: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      & > li:first-child {
        font-weight: bold;
      }
    }

    .navigation__menu__button {
      margin: $spacer-4 0 0 0;
    }
  }
}

#skip-link {
  transition-property: opacity, transform;
  transition-timing-function: $transition-timing-function;
  transition-duration: $transition-duration-default;
  opacity: 0;
  transform: translateY(-100px);
  z-index: 999;
  position: absolute;
  top: 20px;
  left: 30px;
  &:focus {
    transform: translateY(0);
    opacity: 1;
  }
}
