@import '../../scss/required';

.headquarters {
  &-direction {
    margin-bottom: 1.5rem;

    &__name {
      display: inline-block;
      margin-left: 1rem;
    }

    &__desc {
      margin-left: 2.5rem; // svg width + 1rem
    }
  }
  svg {
    fill: $red;
  }

  &-map {
    &__image {
      display: block;
      object-fit: unset;
      max-width: 100%;
      height: auto;
      margin: auto;
    }
  }
}
