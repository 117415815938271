$enable-rfs: false;
$font-family-sans-serif: 'Roboto', sans-serif;
// Font size
$h1-font-size: 64px;
$h2-font-size: 32px;
$h3-font-size: 21px;
$h4-font-size: 16px;

$h1-mobile-font-size: 42px;
$h2-mobile-font-size: 25px;

$p-font-size: 18px;

$body-font-size: 16px;

$small-font-size: 14px;
$caption-font-size: $small-font-size;
$extra-small-font-size: 12px;

// Line height
$h1-line-height: 75px;
$h2-line-height: 48px;
$h3-line-height: 32px;
$h4-line-height: 24px;

$h1-mobile-line-height: 49px;
$h2-mobile-line-height: 36px;

$p-line-height: 32px;

$body-line-height: 24px;

$caption-line-height: 18px;

// Letter spacing
$h1-letter-spacing: -1.5px;
$h2-letter-spacing: -0.5px;
$h3-letter-spacing: -0.33px;
$h4-letter-spacing: 0.2px;

$h1-mobile-letter-spacing: -0.98px;
$h2-mobile-letter-spacing: -0.39px;

$p-letter-spacing: 0.14px;

$body-letter-spacing: 0.57px;

$caption-letter-spacing: 0.5px;

// Colors
$red: #d93c31;
$black: #2e2d2c;
$blue: #1459db;
$gray: #ededed;
$gray-light: #f5f5f5;
$gray-darker: #dbdbdb;
$gray-darkest: #d0d0d0;
$white: white;

$unique: $red;
$primary: $black;

$dark: $black;
$body-color: $black;

$input-bg-color: $gray-light;
$disabled-input-color: #9d9d9c;
$disabled-input-opacity-color: rgba(#000, 0.39);
$input-hover-opacity-color: rgba($red, 0.1);
$input-padding: 12px 34px 12px 16px;
$input-focus-padding: 21px 34px 3px 16px;
$input-border-color: $black;
$input-border-width: 1px;
$input-focus-border-color: $red;
$input-focus-bg-color: $gray;

// Spacers
$spacers: (
  0: 0,
  1: 4px,
  2: 8px,
  3: 12px,
  4: 16px,
  5: 20px,
  6: 24px,
  7: 28px,
  8: 32px,
  9: 36px,
  10: 40px,
  11: 44px,
  12: 48px,
  13: 52px,
  14: 56px,
  15: 60px,
  16: 64px,
);

$spacer-0: map-get($spacers, 0);
$spacer-1: map-get($spacers, 1);
$spacer-2: map-get($spacers, 2);
$spacer-3: map-get($spacers, 3);
$spacer-4: map-get($spacers, 4);
$spacer-5: map-get($spacers, 5);
$spacer-6: map-get($spacers, 6);
$spacer-7: map-get($spacers, 7);
$spacer-8: map-get($spacers, 8);
$spacer-9: map-get($spacers, 9);
$spacer-10: map-get($spacers, 10);
$spacer-11: map-get($spacers, 11);
$spacer-12: map-get($spacers, 12);
$spacer-13: map-get($spacers, 13);
$spacer-14: map-get($spacers, 14);
$spacer-15: map-get($spacers, 15);
$spacer-16: map-get($spacers, 16);

$component-vertical-space: 80px;
$component-vertical-space-mobile: 50px;

$--bs-gutter-y: 1rem;

// Transition
$transition-duration-default: 0.2s;
$transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
