@import '../../scss/required.scss';

.text-field {
  position: relative;
  height: 48px;
  margin-bottom: $spacer-10;

  input {
    @extend %form-control;
  }

  input:placeholder-shown + label {
    @extend %form-control-label;
  }

  input:focus + label,
  label {
    @extend %form-control-focus-label;
  }

  input::placeholder {
    color: transparent;
    display: none;
  }

  input:focus,
  input:not(:placeholder-shown) {
    @extend %form-control-focus;
  }

  input:disabled {
    & + label {
      color: $disabled-input-color;
    }
    border-bottom-color: $disabled-input-color;
  }

  &__error {
    @extend %form-control-error;
  }

  &--error {
    input:focus + label,
    label {
      color: $red;
    }
  }
}
