@import '../../scss/required';

.promo {
  background: $gray-light;
  position: relative;
  padding: $spacer-12;
  margin-bottom: $spacer-3;

  h2 {
    width: 50%;
    z-index: 50;
    position: relative;
    word-break: normal;
    padding-left: $spacer-6;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  &__controls {
    position: absolute;
    right: $spacer-6;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 50;
    max-width: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .btn--tertiary {
      padding: 0;
    }

    span {
      display: inline-block;
      margin: $spacer-3;
    }

    @include media-breakpoint-down(md) {
      right: $spacer-4;
      flex-direction: column;
      span {
        display: block;
      }
    }
  }

  .background {
    background: transparent;
    position: absolute;
    right: 0;
    bottom: 0;

    rect {
      display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    text-align: center;
    height: auto;

    h2 {
      width: 100%;
    }

    .background {
      bottom: 0;
      width: 100%;
      height: auto;
    }

    &__controls {
      position: relative;
      right: 50%;
      transform: translate(50%, 0);
      z-index: 50;
      margin-top: $spacer-12;
      max-width: 100%;

      span {
        display: block;
        margin: $spacer-4;
      }
    }
  }
}
