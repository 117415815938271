@import '../../scss/required.scss';

// inspired by https://loading.io/css/
$spinner-size: 36px;
$spinner-thickness: 3px;
$spinner-container-size: $spinner-size + 2 * ($spinner-thickness);
$spinner-backdrop-zindex: 100;

.spinner {
  display: inline-block;
  position: relative;
  width: $spinner-container-size;
  height: $spinner-container-size;

  > div {
    position: absolute;
    width: $spinner-size;
    height: $spinner-size;
    margin: $spinner-thickness;
    border: $spinner-thickness solid $unique;
    border-radius: 50%;
    animation: spinning 1s $transition-timing-function infinite;
    border-color: $unique transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  &-overlay {
    display: block;
    position: relative;

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: $spinner-backdrop-zindex + 1;
    }
  }

  &__backdrop {
    width: 100%;
    height: 100%;
    background-color: rgba(#fff, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    z-index: $spinner-backdrop-zindex;
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
