@import '../../scss/required';

.testimonials {
  text-align: center;
  position: relative;

  p {
    font-size: $body-font-size;
    margin-bottom: $spacer-8;
  }

  & > div {
    padding-top: 1px;
  }

  button {
    outline: 0;
    border: 0;
    background: transparent;
  }

  button:focus {
    outline: 2px solid $blue;
  }

  &__left-arrow,
  &__right-arrow {
    position: absolute;
    top: 173px;

    &:hover svg {
      fill: $red;
    }
  }

  &__left-arrow {
    left: 0;
  }

  &__right-arrow {
    right: 0;
  }

  &-inset {
    max-width: 700px;
    margin: auto;

    &__top-char {
      font-size: 120px;
      color: $gray-darkest;
      width: 40px;
      display: block;
      margin: auto;
      margin-top: 42px;
      user-select: none;
    }

    &__img-container {
      overflow: hidden;
      width: 66px;
      height: 66px;
      border-radius: 50%;
      border: 1px solid $red;
      padding: 3px;
      margin: auto;
      user-select: none;
    }

    &__img {
      width: 58px;
      height: 58px;
      overflow: hidden;
      border-radius: 50%;

      img {
        width: 100%;
        height: auto;
      }
    }

    &__quote {
      display: block;
      margin-bottom: $spacer-8;
      margin-top: $spacer-8;
      text-align: center;

      // quotes are already part of Sitecore string
      &::before,
      &::after {
        content: '';
      }
    }

    @include media-breakpoint-down(md) {
      .testimonials__left-arrow {
        top: $spacer-16;
        margin-top: $spacer-4;
      }

      .testimonials__right-arrow {
        top: $spacer-16;
        margin-top: $spacer-4;
      }
    }
  }
}
