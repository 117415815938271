::selection {
  color: #fff;
  background: $unique;
  text-shadow: none;
}

svg {
  @include transition(fill);
}

img {
  margin-bottom: 0;
}

[data-navigation-label] {
  &,
  [id] {
    scroll-margin-top: 90px;
  }
}
