@import '../../scss/required.scss';

@mixin file-selector-button {
  color: $white;
  background-color: $primary;
  margin: -12px $spacer-4 -12px -16px;
  padding: 12px $spacer-4;
  border: 0;
  pointer-events: none;
  @include transition(background-color);
}

.file-input {
  margin-bottom: $spacer-10;

  .text-field + &,
  .text-area + &,
  .select + & {
    .file-input__label {
      margin-top: -$spacer-2;
    }
  }

  &__error {
    @extend %form-control-error;
  }

  &__label {
    display: block;
    color: $black;
    margin-bottom: $spacer-1;
  }

  &__input {
    @extend %form-control;
    appearance: none;
    cursor: pointer;
    overflow: hidden;

    &:hover,
    &:focus {
      &::file-selector-button {
        background-color: $red;
      }
      &::-webkit-file-upload-button {
        background-color: $red;
      }
    }

    &:focus {
      outline: none;
    }

    &::file-selector-button {
      @include file-selector-button;
    }

    // for older webkit/blink browsers
    &::-webkit-file-upload-button {
      @include file-selector-button;
    }
  }
}
