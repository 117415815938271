@import '../../scss/required';

.career-recruiter {
  &-info {
    &__image {
      &-border {
        max-width: fit-content;
        border: 1px solid $red;
        border-radius: 50%;
      }

      img {
        max-height: 66px;
        max-width: 66px;
        border: 4px solid transparent;
        border-radius: 33px;
        object-fit: cover;

        @include media-breakpoint-up(lg) {
          max-height: 111px;
          max-width: 111px;
          border: 8px solid transparent;
          border-radius: 56px;
        }
      }
    }

    .h4 {
      margin-bottom: 0;
    }

    .caption {
      font-style: italic;
    }
  }

  hr {
    margin: 1rem 0 0.5rem;
  }

  &-contacts {
    svg {
      fill: $red;
    }

    &__email,
    &__phone {
      font-weight: bold;
      text-decoration: underline;
      margin-left: 1rem;
    }
  }
}
