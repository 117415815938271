@import '../../scss/required.scss';

.text-area {
  position: relative;
  height: 95px;
  margin-bottom: $spacer-10;

  textarea {
    @extend %form-control;
    position: relative;
    height: 100%;
    resize: vertical;
    min-height: 48px;
  }

  textarea:placeholder-shown + label {
    @extend %form-control-label;
  }

  textarea:focus + label,
  label {
    @extend %form-control-focus-label;
  }

  textarea::placeholder {
    color: transparent;
    display: none;
  }

  textarea:focus,
  textarea:not(:placeholder-shown) {
    @extend %form-control-focus;
  }

  textarea:disabled {
    & + label {
      color: $disabled-input-color;
    }
    border-bottom-color: $disabled-input-color;
  }

  &__error {
    @extend %form-control-error;
  }

  &--error {
    textarea:focus + label,
    label {
      color: $red;
    }
  }
}
