@import '../../scss/required';

.insights-normal {
  span {
    font-size: $caption-line-height;

    @include media-breakpoint-down(lg) {
      font-size: $small-font-size;
    }
  }

  h2 {
    margin-bottom: $spacer-4;
  }

  & > .btn {
    margin: $spacer-7 0 $spacer-7 $spacer-2;
  }
}
