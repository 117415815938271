@import '../../scss/required';

.insights-inset {
  position: relative;

  h3 {
    margin-top: $spacer-6;
    margin-bottom: $spacer-5;
  }

  img {
    object-fit: contain;
    object-position: top;
  }

  a,
  button {
    position: absolute;
    bottom: $spacer-0;
  }

  &__img-wrapper {
    text-align: center;
  }

  &--normal {
    display: flex;
    flex-direction: column;
    font-size: $small-font-size;
    height: 100%;

    img {
      display: inline;
      object-fit: contain;
      object-position: unset;
      max-width: 100%;
      width: 100%;
      height: auto;
    }

    a,
    button {
      left: $spacer-10;
    }

    &__content {
      background: $gray-light;
      padding-top: 1px;
      padding-bottom: 90px;
      flex-grow: 1;

      & > .btn {
        margin-bottom: $spacer-6;
        font-size: $body-font-size;
      }

      p:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-down(lg) {
        p {
          font-size: $small-font-size;
        }
      }
    }
  }

  &--wide {
    height: 100%;

    & > .container {
      padding: 0;
      height: 100%;

      & > .g-0 {
        height: 100%;
      }
    }

    img {
      width: 50%;
      height: auto;
      object-fit: cover;

      @include media-breakpoint-down(md) {
        width: 100%;
        position: relative;
      }
    }

    &__content {
      position: relative;
      padding-bottom: 90px;
      background: $input-bg-color;

      & > p {
        @include media-breakpoint-down(xl) {
          font-size: $small-font-size;
        }
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        left: 0;
      }
      & > .btn {
        margin-bottom: $spacer-6;
        margin-left: $spacer-5;
        font-size: $body-font-size;
        left: $spacer-3;
      }
    }
  }
}
