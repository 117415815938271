@import '../../scss/main.scss';

.btn {
  border-radius: 30px;
  padding: 11px 15px 11px 30px;
  font-weight: bold;
  outline: 0;
  border: 1px solid transparent;
  display: inline-block;
  text-decoration: none;
  transition-property: color, border, background-color, box-shadow;
  transition-timing-function: $transition-timing-function;
  transition-duration: $transition-duration-default;

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &:focus {
    text-decoration: none;

    .btn__icon {
      transform: translateX(4px);
    }
  }

  &__icon {
    margin-left: 25px;
    @include transition(transform);
  }

  &--unique {
    background-color: $unique;
    color: $white;

    svg {
      fill: $white;
    }

    &:hover {
      color: $white;
    }
  }

  &--primary {
    background-color: $primary;
    color: $white;

    svg {
      fill: $white;
    }

    &:hover {
      background-color: $unique;
      color: $white;
    }
  }

  &--secondary {
    border: 1px solid $primary;
    background-color: $white;
    color: $primary;

    svg {
      fill: $unique;
    }

    &:hover {
      border: 1px solid $unique;
    }

    &:focus {
      border-color: $primary;
    }
  }

  &--tertiary {
    padding: 11px 0;
    border-radius: 0;
    color: $primary;
    background-color: transparent;

    svg {
      margin-left: 5px;
      fill: $primary;
    }

    &:hover {
      color: $unique;

      svg {
        margin-left: 5px;
        fill: $unique;
      }
    }
  }

  &--icon {
    padding: 0;
    border-radius: 0;
    border: none;

    svg {
      margin-left: 0;
    }

    &:hover,
    &:active {
      background-color: $gray;

      svg {
        fill: $unique;
      }
    }
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.65;
  }

  &:focus {
    box-shadow: 0 0 0 0.15rem $blue;
    outline: none;
  }
}
