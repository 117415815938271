@import '../../scss/required.scss';

.language-switcher {
  &--dropdown {
    position: relative;
  }

  &--inline {
    margin-left: $spacer-4;
    margin-right: $spacer-4;
  }

  &__toggle {
    display: flex;
    align-items: center;
    border-radius: 0;
    padding: $spacer-3;
    background-color: $white;

    .icon {
      fill: $black;
      margin-left: 0;
      transition-property: transform, fill;
    }

    &:hover,
    &:focus {
      color: $unique;

      .icon {
        fill: $unique;
      }
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    &--open {
      .icon {
        transform: rotateZ(270deg);
      }
    }
  }

  .dropdown-menu {
    width: 100%;
    padding: 0;
    min-width: 0;
    border: 1px solid $black;
    border-radius: 0;
  }

  .dropdown-item {
    width: calc(100% + 2px);
    padding: ($spacer-3 + 2px) $spacer-3;
    font-weight: $font-weight-bold;
    text-align: center;
    border: 1px solid transparent;
    margin: -1px;

    &:active,
    &:hover,
    &:focus {
      color: currentColor;
      text-decoration: none;
      border-color: $unique;
      outline: 0;
      background-color: $gray-light;
    }

    &:focus {
      box-shadow: 0 0 0 0.15rem $blue;
    }
  }

  &__list {
    list-style: none;
    margin: 0 0 0 -#{$spacer-5};
    padding: 0;
    display: flex;

    .language-switcher__language {
      display: block;
      font-weight: $font-weight-bold;
      padding: $spacer-6 $spacer-5;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 25%;
        height: 1px;
        background-color: $unique;
        position: absolute;
        left: 50%;
        bottom: 0;
        opacity: 0;
        transform: translateX(-50%);
        @include transition(opacity);
      }

      &:focus,
      &:hover {
        text-decoration: none;

        &::after {
          opacity: 1;
        }
      }
    }

    .language-switcher__language--active {
      &::after {
        opacity: 1;
      }
    }
  }
}
