@import '../../scss/required';

.awards {
  &-headline,
  &-headline__sub {
    margin-bottom: 1rem;
  }

  .btn {
    margin: 0;
  }

  &-inset {
    padding: 1rem;
    margin-bottom: 0.5rem;
    border: 1px solid $gray;

    &__img {
      display: block;
      width: 100%;
      height: 68px;
      object-fit: contain;
      align-self: flex-start;

      @include media-breakpoint-up(md) {
        align-self: center;
      }
    }

    &__text {
      margin-bottom: 0;
    }
  }
}
