@import '../../scss/main.scss';

.grid-info {
  // if there is an adjacent grid info component => we don't want that big vertical space between them
  & + .grid-info.component {
    margin-top: -$component-vertical-space-mobile;

    @include media-breakpoint-up(lg) {
      margin-top: -$component-vertical-space;
    }
  }
}

.caption_h2 .grid_caption {
  @extend .h2;
}
.caption_h3 .grid_caption {
  @extend .h3;
}
.caption_h4 .grid_caption {
  @extend .h4;
}
