@import '../../scss/required';

.insights-wide {
  h2 {
    margin-bottom: $spacer-4;
  }

  & > .btn {
    margin-top: $spacer-7;
  }
}
