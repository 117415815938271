@import '../../scss/required';

.links {
  position: relative;

  &-heading {
    margin-bottom: 2rem;

    h3 {
      margin-bottom: $spacer-16;

      @include media-breakpoint-down(lg) {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      padding: 0.5rem 0;
    }
  }
}
