@import '../../scss/required';

$container-max-width-sm: map-get($container-max-widths, 'sm');
$container-max-width-md: map-get($container-max-widths, 'md');
$container-max-width-lg: map-get($container-max-widths, 'lg');
$container-max-width-xl: map-get($container-max-widths, 'xl');
$container-max-width-xxl: map-get($container-max-widths, 'xxl');

.slim_content__container {
  > .component {
    @include media-breakpoint-up(lg) {
      padding-inline: 0;
      max-width: calc(#{$container-max-width-lg} - #{$container-max-width-lg}/ 12 * 2);
    }

    @include media-breakpoint-up(xl) {
      max-width: calc(#{$container-max-width-xl} - #{$container-max-width-xl}/ 12 * 4);
    }

    @include media-breakpoint-up(xxl) {
      max-width: calc(#{$container-max-width-xxl} - #{$container-max-width-xxl}/ 12 * 6);
    }
  }
  .big-image {
    @include media-breakpoint-up(lg) {
      padding-inline: 0;
    }
    .big-image__img {
      height: 60%;
    }
  }

  .big-image__text {
    margin-inline: auto;
    padding-inline: 0;
    @include media-breakpoint-up(sm) {
      max-width: $container-max-width-sm;
    }
    @include media-breakpoint-up(md) {
      max-width: $container-max-width-md;
    }
    @include media-breakpoint-up(lg) {
      max-width: calc(#{$container-max-width-lg} - #{$container-max-width-lg}/ 12 * 2);
    }

    @include media-breakpoint-up(xl) {
      max-width: calc(#{$container-max-width-xl} - #{$container-max-width-xl}/ 12 * 4);
    }

    @include media-breakpoint-up(xxl) {
      max-width: calc(#{$container-max-width-xxl} - #{$container-max-width-xxl}/ 12 * 6);
    }
  }

  & > .big-image.img__overflow {
    max-width: none;
    padding-inline: 0;
    @include media-breakpoint-up(xl) {
      max-width: calc(#{$container-max-width-xl} - #{$container-max-width-xl}/ 12 * 2);
    }

    @include media-breakpoint-up(xxl) {
      max-width: calc(#{$container-max-width-xxl} - #{$container-max-width-xxl}/ 12 * 4);
    }

    .big-image__text {
      padding-inline: $container-padding-x;
      @include media-breakpoint-up(lg) {
        padding-inline: 0;
      }
    }
  }

  & > .big-image.img__full_width {
    padding-inline: 0;
    max-width: none;

    .big-image__text {
      padding-inline: $container-padding-x;
      @include media-breakpoint-up(lg) {
        padding-inline: 0;
      }
    }
  }

  .page-intro > div {
    width: 100% !important;
  }
}
