@import '../../scss/required.scss';

$select-option-padding: 12px;

.select {
  position: relative;
  font-size: $body-font-size;
  margin-bottom: $spacer-10;

  &--mobile {
    select {
      @extend %form-control;
      padding: 12px 34px 0 16px;
      height: 48px;
      outline: 0;
      cursor: pointer;

      &:hover {
        background: $gray;
        border-bottom-color: $input-focus-border-color;
      }
    }

    .label {
      position: absolute;
      top: -2px;
      left: 16px;
      font-size: $extra-small-font-size;
    }

    &--disabled {
      color: $disabled-input-color;
      border-bottom-color: $disabled-input-color;
    }
  }

  .selected-text {
    @extend %form-control;
    border: 1px solid transparent;
    border-bottom: $input-border-width solid $input-border-color;
    height: 48px;
    text-align: left;
    padding: 0;
    outline: 0;
    position: relative;

    &--disabled {
      color: $disabled-input-color;
      border-bottom-color: $disabled-input-color;
    }

    &:hover {
      @extend %form-control-focus;
      padding: 0;
    }

    &:focus {
      border: 2px solid $blue;
    }

    &--disabled:hover {
      border-bottom-color: $disabled-input-color;
      background: $input-bg-color;
    }

    &--disabled {
      .select__icon {
        fill: $disabled-input-color;
      }
    }

    &--active {
      border: 1px solid black;
      border-bottom: 1px solid $red;
      background: $white;

      &:focus {
        border: 1px solid black;
        border-bottom: 1px solid $red;
      }

      .select__icon {
        transform: translateY(-50%) rotateZ(270deg);
        fill: $red;
      }
    }

    &--value {
      padding: 0;

      .label {
        font-size: $extra-small-font-size;
        position: relative;
        left: 16px;
        top: -13px;
      }

      .value {
        position: absolute;
        top: 21px;
        left: 16px;
      }
    }

    &--error .label {
      color: $red;
    }
  }

  .select__icon {
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: $spacer-4;
    transform: translateY(-50%) rotateZ(90deg);
    @include transition(transform);
  }

  .select__options {
    position: absolute;
    width: 100%;
    z-index: 1000;
    border: 1px solid $black;
    border-top: 0;
    margin: 0;
    padding: 0;

    .select__option {
      list-style-type: none;
      padding: $select-option-padding;
      background: $white;
      border: 1px solid $white;
      cursor: pointer;
      min-height: 50px;
      transition-property: background-color;
      transition-duration: $transition-duration-default;
      transition-timing-function: $transition-timing-function;
    }

    .select__option:hover,
    .select__option--selected {
      border: 1px solid $red;
      background: $input-bg-color;
      margin-left: -1px;
      margin-right: -1px;
      padding-left: calc(#{$select-option-padding} + 1px);
      padding-right: calc(#{$select-option-padding} + 1px);

      &:last-child {
        margin-bottom: -1px;
      }
    }
  }

  &__error {
    @extend %form-control-error;
  }
}
