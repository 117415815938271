@import '../../scss/required';

.partner {
  position: relative;

  &-inset {
    text-align: center;
    padding: $spacer-3;
    border: 1px solid $gray-darker;
    height: 100%;
    @include transition(border);

    &--clickable:hover {
      border-color: $red;
    }

    &__img {
      width: 100%;
      height: auto;
      margin-bottom: $spacer-2;
    }

    a {
      font-size: $extra-small-font-size;

      &:hover {
        text-decoration: none;
      }

      &.disabled {
        pointer-events: none;
      }

      &::after {
        content: '';
        width: calc(100% - #{$spacer-4});
        height: calc(100% - #{$spacer-4});
        position: absolute;
        top: $spacer-2;
        left: $spacer-2;
        border: 2px solid transparent;
        @include transition(border);
      }

      &:focus {
        outline: 0;
      }

      &:focus::after {
        border-color: $blue;
      }
    }
  }
}
